import {useTranslation} from 'next-i18next'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Counter from 'src/components/forms/Counter'
import {GuestSelectType} from 'src/types/guestSelect'
import {Fragment, useEffect, useRef, useState} from 'react'
import {usePropertyCanAcceptAnimals} from 'src/hooks/property'
import AppIcon from 'src/components/elements/icons/AppIcon'
import Hidden from 'src/components/helpers/Hidden'

export default function GuestSelect(props: {
  maximumGuests?: number | null | undefined
  maximumAnimals?: number | null | undefined
  rules?: string[] | null
  guests: GuestSelectType
  onChangeGuest: (guestInfo: GuestSelectType) => void
}) {
  const {maximumGuests, maximumAnimals, rules, guests, onChangeGuest} = props

  const {t} = useTranslation('common')

  const canAcceptAnimals = usePropertyCanAcceptAnimals(rules)
  const timeoutId = useRef<any>(null)
  //init empty
  const [localGuests, setLocalGuests] = useState<GuestSelectType>({
    adults: 0,
    children: 0,
    infants: 0,
    animals: 0,
  })

  useEffect(() => {
    setLocalGuests(guests)
  }, [guests])

  const reachedToMaximumGuests = maximumGuests
    ? localGuests.adults + localGuests.children >= maximumGuests
    : false

  const reachedToMaximumAnimals = maximumAnimals
    ? localGuests.animals >= maximumAnimals
    : false

  const handleChange =
    (guestType: keyof GuestSelectType) => (increase: boolean) => {
      if (
        increase &&
        reachedToMaximumGuests &&
        (guestType === 'adults' || guestType === 'children')
      ) {
        return
      }

      const newGuests: GuestSelectType = {...localGuests}
      if (increase) {
        newGuests[guestType] = localGuests[guestType] + 1
      }

      if (!increase && localGuests[guestType] > 0) {
        newGuests[guestType] = localGuests[guestType] - 1
      }
      if (!increase && localGuests[guestType] <= 0) {
        newGuests[guestType] = 0
      }

      setLocalGuests(newGuests)
      //like a debounce function, only send to props after 500ms we change selection
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        onChangeGuest(newGuests)
      }, 750)
    }

  const onChange = (guestType: keyof GuestSelectType) => (value: number) => {
    if (
      maximumGuests &&
      guestType === 'adults' &&
      value + localGuests['children'] > maximumGuests
    ) {
      return
    }

    if (
      maximumGuests &&
      guestType === 'children' &&
      value + localGuests['adults'] > maximumGuests
    ) {
      return
    }

    //if we have maximum animals and we are trying to add more than maximum
    if (
      maximumAnimals &&
      guestType === 'animals' &&
      value + localGuests['animals'] > maximumAnimals
    ) {
      return
    }

    const newGuests: GuestSelectType = {...localGuests}
    newGuests[guestType] = value

    onChangeGuest(newGuests)
  }

  return (
    <ul className="list-none p-0 space-y-5 py-5" id="guest_selection">
      <Item
        label={`${t('adults')}s`}
        canIncrease={!reachedToMaximumGuests}
        description={t('adults_description')}
        value={localGuests.adults}
        handleChange={handleChange('adults')}
        onChange={onChange('adults')}
      />
      <Item
        label={t('children')}
        canIncrease={!reachedToMaximumGuests}
        description={t('children_description')}
        value={localGuests.children}
        handleChange={handleChange('children')}
        onChange={onChange('children')}
      />
      <Item
        label={`${t('infants')}s`}
        description={t('infants_description')}
        value={localGuests.infants}
        handleChange={handleChange('infants')}
        onChange={onChange('infants')}
      />
      <div>
        <Item
          disabled={!canAcceptAnimals}
          canIncrease={!reachedToMaximumAnimals}
          label={t('animals')}
          description={t('animals_description')}
          value={localGuests.animals}
          handleChange={handleChange('animals')}
          onChange={(e) => {
            if (!canAcceptAnimals) {
              return
            }
            onChange('animals')
          }}
        >
          <Hidden when={canAcceptAnimals}>
            <div className="flex flex-row justify-start items-center">
              <AppIcon name="warning" className="w-3 h-3 mr-2" />
              <p className="text-xxs my-2">
                {t('property_dont_accept_animals')}
              </p>
            </div>
          </Hidden>
        </Item>
      </div>
    </ul>
  )
}

function Item(props: {
  label: string
  description: string
  value: number
  handleChange: (increase: boolean) => void
  onChange: (value: number) => void
  disabled?: boolean
  canIncrease?: boolean
  children?: React.ReactNode
}) {
  const {
    label,
    description,
    value,
    handleChange,
    onChange,
    disabled = false,
    canIncrease = true,
  } = props

  const localDecrement = () => {
    handleChange(false)
  }

  const localIncrement = () => {
    if (!canIncrease) return
    handleChange(true)
  }

  const localOnChange = (newValue: number) => {
    if (!canIncrease) {
      if (newValue > value) return
    }
    onChange(value)
  }

  return (
    <li>
      <div className="flex flex-row justify-between items-center w-full">
        <Fragment>
          <div>
            <AppTypography variant="action" neutralColor={800}>
              {label}
            </AppTypography>
            <AppTypography variant="body">{description}</AppTypography>
          </div>
          <Counter
            disabled={disabled}
            value={value}
            handleDecrement={localDecrement}
            handleIncrement={localIncrement}
            handleChange={localOnChange}
          />
        </Fragment>
      </div>
      {props.children}
    </li>
  )
}
