import {makeStyles} from '@material-ui/core/styles'
import {onChangeIntegerHandler} from 'src/utils/dom'
import EnhancedInput from 'src/components/forms/textField/EnhancedInput'
import clsx from 'clsx'
import AppIcon from '../elements/icons/AppIcon'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  text: {
    '& input': {
      border: 'none !important',
      width: '24px',
      height: spacing(46 / 8),
      textAlign: 'center',
      padding: 0,
      '&:focus': {
        border: `1px solid ${palette.divider} !important`,
        borderRadius: `${spacing(1)}px !important`,
      },
    },
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    /* Firefox */
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}))

export default function Counter(props: {
  handleDecrement: () => void
  handleIncrement: () => void
  handleChange: (value: number) => void
  value: number
  disabled?: boolean
}) {
  const classes = useStyles()

  const {
    handleDecrement,
    handleIncrement,
    handleChange,
    value,
    disabled = false,
  } = props

  return (
    <div className="flex flex-row justify-start items-center gap-2">
      <button
        type="button"
        onClick={() => {
          if (disabled) return
          handleDecrement()
        }}
        disabled={disabled}
        className={clsx(
          'w-12 min-w-12 h-12 flex flex-col justify-center items-center gap-1 rounded-md',
          disabled ? 'opacity-50 pointer-events-none' : 'hover:bg-gray-100',
        )}
      >
        <AppIcon className="pointer-events-none" name="minus" />
      </button>
      <EnhancedInput
        disabled={disabled}
        className={classes.text}
        value={value}
        type="number"
        onChange={onChangeIntegerHandler<number>(handleChange, 0)}
      />
      <button
        type="button"
        onClick={() => {
          if (disabled) return
          handleIncrement()
        }}
        disabled={disabled}
        className={clsx(
          'w-12 min-w-12 h-12 flex flex-col justify-center items-center gap-1 rounded-md',
          disabled ? 'opacity-50 pointer-events-none' : 'hover:bg-gray-100',
        )}
      >
        <AppIcon className="pointer-events-none" name="plus" />
      </button>
    </div>
  )
}
